import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Dashboard from "./pages/Dashboard";
import AssessmentForm from "./pages/AssessmentForm";
import RecommendedProfessinalProfilePage from "./pages/RecommendedProfessinalProfilePage";
import ViewAssessmentReport from "./pages/ViewAssessmentReport";
import BlogScreen from "./components/blogs/BlogScreen";
import SubscriptionPlans from "./pages/SubscriptionPlans";
import MeetingsPage from "./pages/meeting/MeetingsPage";
import PaymentProcessingPage from "./pages/PaymentProcessingPage";
import {RecommendationPage} from "./components/v1/RecommendationPage";
import {ProfilePage} from "./pages/profile/ProfilePage";
import {DataRoomPage} from "./components/data-room/DataRoomPage";
import {UploadFiles} from "./components/data-room/UploadFiles";
import {Login} from "./pages/authentication/Login";
import {ForgotPassword} from "./pages/authentication/ForgotPassword";
import {ResetPasswordOTP} from "./pages/authentication/ResetPasswordOTP";
import {SignUp} from "./pages/authentication/SignUp";
import {VerifyAccountOTP} from "./pages/authentication/VerifyAccountOTP";
import {DownloadReportPage} from "./pages/downloadReport/DownloadReportPage";
import { MessagePage } from "./pages/messages/messagePage";
import { RemediationPage } from "./pages/remediation/remediationPage";

export const router = createBrowserRouter([
    {path: "/", element: <App />},
    {path: "/dashboard", element: <Dashboard />},
    {path: "/assessmentForm", element: <AssessmentForm />},
    {path: "/signUp", element: <SignUp />},
    {path: "/login", element: <Login />},
    {path: "/forgotPassword", element: <ForgotPassword />},
    {path: "/resetPassword", element: <ResetPasswordOTP />},
    {path: "/recommendation", element: <RecommendationPage />},
    {path: "/professional-profile", element: <RecommendedProfessinalProfilePage />},
    {path: "/inbox", element: <MessagePage />},
    {path: "/remediationTimelines", element: <RemediationPage />},
    {path: "/viewAssessmentReport", element: <ViewAssessmentReport />},
    {path: "/download-report", element: <DownloadReportPage />},
    {path: "/blogs", element: <BlogScreen />},
    {path: "/pricing", element: <SubscriptionPlans />},
    {path: "/meetings", element: <MeetingsPage />},
    {path: "/payment-success", element: <PaymentProcessingPage />},
    {path: "/api/v1/payment-success", element: <PaymentProcessingPage />},
    {path: "/verify-account", element: <VerifyAccountOTP />},
    {path: "/my-profile", element: <ProfilePage />},
    {path: "/data-room", element: <DataRoomPage />},
    {path: "/data-room/upload", element: <UploadFiles />}
]);

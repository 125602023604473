import React, { useEffect, useState, useRef } from "react";
import {Input, Spin, Modal, Upload, Button, message} from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { serverRequest, serverRequestNoHeaders } from "../../Utils/server_request";
import { getUserDetails, notifyUser, getFileNameFromUrl, removePublicFromUrl } from "../../Utils/general";
import { useNavigate } from "react-router-dom";

export const MessagePage = (props) => {
    const navigate = useNavigate();
    const chatRef = useRef(null);
    const [recentList, setRecentList] = useState([])
    const [originalList, setOriginalList] = useState([])
    const [messages, setMessages] = useState([])
    const [recentListLoader, setRecentListLoader] = useState(false)
    const [meessagesLoader, setMessagesLoader] = useState(false)
    const [attachmentModal, setAttachmentModal] = useState(false)
    const [activeUser, setActvieUser] = useState({})
    const [messageTxt, setMessageTxt] = useState("")
    const [searchQuery, setSearchQuery] = useState("");


    const toggleRecentLoader = () => setRecentListLoader(prev => !prev);
    const toggleMessagesLoader = () => setMessagesLoader(prev => !prev);
    const toggleAttachmentModal = () => setAttachmentModal(prev => !prev);

    useEffect(() => {
        getRecentMessages()

        if (chatRef.current) {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [messages])

    const getRecentMessages = () => {
        toggleRecentLoader()
        serverRequest('GET', 'get-conversations').then(result => {
            // console.log(result);
            toggleRecentLoader()
            if(result.data){
                setRecentList(result.data)
                setOriginalList(result.data)
            }
        })
    }

    const selectUser = (user) => {
        setActvieUser(user)
        fetchMessages(user)
    }

    const fetchMessages = (user=null) => {
        toggleMessagesLoader()
        serverRequest('GET', `get-messages/${user ? user.id : activeUser?.id}`).then(result => {
            toggleMessagesLoader()
            console.log(result)
            if(result){
                setMessages(result)
            }
        })
    }

    const sendMessage = () => {
        if(setMessageTxt === ""){
            return notifyUser('error', 'Message field can not be empty!', 'Validation')
        }
        serverRequest('POST', `send-message/user/${activeUser?.id}`, {message: messageTxt, is_file:false}).then(result => {
            if(result.data){
                setMessageTxt('')
                setMessages((prevMessages) => [...prevMessages, result.data]);
            }
        })
    }

    const searchFunction = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (!query) {
            setRecentList(originalList); // Restore original list when search is cleared
            return;
        }

        const filteredList = originalList.filter(item =>
            item.receiver.business_name.toLowerCase().includes(query)
        );

        setRecentList(filteredList);
    };

    const onFileChange = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await serverRequestNoHeaders('POST', 'file-upload', formData);

            if (response.success) {
                message.success(`${file.name} uploaded successfully.`);

                const fileUrl = response.file;

                serverRequest('POST', `send-message/user/${activeUser?.id}`, {message: fileUrl, is_file:true}).then(result => {
                    if(result.data){
                        setMessages((prevMessages) => [...prevMessages, result.data]);
                        toggleAttachmentModal()
                    }
                })

                return false; // Prevent Upload component from auto-uploading
            } else {
                message.error(`${file.name} upload failed.`);
                return false;
            }
        } catch (error) {
            message.error(`${file.name} upload failed: ${error.message}`);
            return false;
        }
    }

    

    return (
       <div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <p className="lead">Inbox</p>
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="Search contact"
                        value={searchQuery}
                        onChange={searchFunction}
                        allowClear
                    />
                    <div className="mt-5">
                        <Spin spinning={recentListLoader}>
                            {recentList.length > 0 ? recentList.map(item => 
                                <div className="recentMessageCard mb-4" key={item.id} style={{cursor:'pointer'}} onClick={()=> selectUser(item.receiver)}>
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                                            <div className="bg-recentMessage"><i className="bi bi-broadcast"/></div>
                                        </div>
                                        <div className="col-lg-7 col-md-7 col-sm-6 col-xs-6">
                                            {item?.is_image ? 
                                                <a className="text-small-message mb-0 text-decoration-none text-black" href="#"><i className="bi bi-file-pdf"/><br/> {getFileNameFromUrl(item.message)}</a>
                                            :
                                            <>
                                                <p className="mb-1 fw-bold">{item.receiver?.business_name}</p>
                                                <p className="mb-1 text-small">{item.message}</p>
                                            </> }
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p className="text-small">{item.created_at_human}</p>
                                        </div>
                                    </div>
                                </div>) : 
                                <div>
                                    <p className="">No recent messages</p>
                                    <a className="btn btn-primary btn-small" href='/recommendation'>View Recommendation</a>
                                </div>
                            }
                        </Spin>
                    </div>
                </div>
                {activeUser?.business_name && (
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <Spin spinning={meessagesLoader}>
                            <div className="mx-auto message-width">
                                <div className="message-header">
                                    <p className="mb-1 fw-bold">{activeUser?.business_name}</p>
                                </div>
                                <div className="message-content" ref={chatRef}>
                                    {messages.map(item =>
                                            <div className={`${item?.sender.id == getUserDetails()?.id ? 'message-card-right': 'message-card-left'} p-3 mb-3`}>
                                                {item.is_image ?
                                                <a className="text-small-message mb-0 text-decoration-none text-black" href={removePublicFromUrl(item.message)} target="_blank"><i className="bi bi-file-pdf"/> <br/>{getFileNameFromUrl(item.message)}</a>
                                            :
                                            <>
                                                <p className="text-small-message mb-0">{item.message}</p>
                                                <p className="text-small mb-0 mt-2">{item.created_at_human}</p>
                                            </>
                                        }
                                        </div>
                                    )}
                                </div>
                                <div className="message-actions">
                                    <div className="p-3">
                                        <textarea 
                                            name="message" 
                                            placeholder="Write a message" 
                                            id="message-actions-textarea" 
                                            value={messageTxt}
                                            onChange={e => setMessageTxt(e.target.value)}
                                        />
                                        <div class="chat-input">
                                            <i class="bi bi-paperclip attach-icon" onClick={toggleAttachmentModal}></i>
                                            <i class="bi bi-send send-icon" onClick={sendMessage}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                        
                    </div>
                )}
            </div>

            <Modal onCancel={toggleAttachmentModal} footer={false} destroyOnClose={true} open={attachmentModal} centered={true}>
                <div className="p-4">
                    <Upload beforeUpload={onFileChange} showUploadList={false}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
            </Modal>
       </div>
    )
}
import React, {useState} from "react";
import { calculateMatchPercentage, extractNumber, isDatePast, notifyUser } from "../../Utils/general";
import { Avatar, Progress, Spin, Tag } from "antd";
import { serverRequest } from "../../Utils/server_request";
import moment from "moment";

export const ProjectDetails = ({ data, getData }) => {

    const [loading, setLoading] = useState(false)

    const handleRefresh = () => {
        getData();
    };

    const completeProjet = (id) => {
        setLoading(true)

        serverRequest('POST', `projects/${id}/complete`).then(result => {
            setLoading(false)
            if(result.success){
                notifyUser('success', 'Project status updated accordingly!', 'Project')
                handleRefresh()
            }else{
                notifyUser('error',result.message, 'Error')
            }
        }).catch(error => {
            notifyUser('error',error.message, 'Error')
        })
    }
    
    const approveProject = (id) => {
        setLoading(true)
        serverRequest('POST', `assignments/${id}/approve`).then(result => {
            setLoading(false)
            if(result.success){
                notifyUser('success', result?.message, 'Project')
                handleRefresh()
            }else{
                notifyUser('error',result?.message, 'Error')
            }
        }).catch(error => {
            setLoading(false)
            notifyUser('error',error.message, 'Error')
        })
    }

    return (
        <div className="p-4">
            <p className="lead">{data?.title}</p>
            <small>Date: {moment(data?.created_at).format('LL')}</small>
             <p className="text-small-message mt-3">{data?.description}</p>
             <hr/>
             <div className="bg-light-yellow p-4 mb-5">
                <p><i className="bi bi-broadcast-pin"/> Skill required for project</p>
                <p>{data?.skill}</p>
             </div>
            {data?.milestones.map((milestone, index) => 
                <div key={index} className="mb-5">
                    <p className="text-small-message"
                    style={{ textDecoration: milestone.completed ? "line-through" : "none" }}><i className="bi bi-geo"/> Milestone {index+1}</p>
                    <p className="text-small-message">{milestone?.title}</p>
                    <div className="bg-light-blue p-4">
                        <p className=""><i className="bi bi-hourglass-split"/>  Tasks ({milestone?.hours} hr)</p>
                        <ul>{milestone.milestone_task.map((task, index) => <li key={index}>{task?.task}</li> )}</ul>
                    </div>
                </div>
            )}


            {data?.status == 'pending' && data?.requests.length > 0 ? 
            <div>
                <p className="mt-5">Professionals requested to take on this project</p>
                {data?.requests.map(prof => 
                    <div className="shadow-sm bg-white p-3 mb-3" key={prof?.user?.id}>
                        <div className="d-flex">
                            <Avatar size="medium" src={`https://robohash.org/${prof?.user?.name}.png`} style={{minHeight: '40px',minWidth: '40px'}} />
                            <div style={{marginLeft: '10px'}} className="w-100">
                                <p className="mb-1 fw-bold">{prof?.user?.business_name}</p>
                                <p className="text-small text-blue fw-bold">Industry: {prof?.user?.industry}</p>
                                <div>
                                    <table class="table mb-3">
                                        <thead>
                                            <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Hours</th>
                                            <th scope="col">Est Budget</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prof.proposal.map((item, index) => 
                                                <tr key={index}>
                                                    <td>Milestone {index + 1}</td>
                                                    <td>{item?.hours}</td>
                                                    <td>${item?.budget}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> 
                                    <button className="btn btn-primary btn-small" disabled={loading} onClick={() => approveProject(prof?.id)}>{loading ? 'Assigning...': 'Approve Professional for Project'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
            </div>
                : ''
            }

            {data?.status !== 'pending' ? 
            <div>
                <div className="bg-light-blue p-3 mb-3">
                    <p className="mb-0 fw-bold">Summary of this Project</p>
                </div>
                {data.milestones.map((milestone, index) => {
                    return (
                    <div key={index} className="mb-4">
                        <h6 className="fw-bold">Milestone {index + 1}</h6>
                        <div className="row">
                        <div className="col-6">
                            <p>Tasks</p>
                        </div>
                        <div className="col-6 text-end">
                            <p>Total: {milestone.milestone_task.length}</p>
                        </div>
                        <div className="col-6">
                            <p>Hours Required</p>
                        </div>
                        <div className="col-6 text-end">
                            <p>Total: {milestone.hours}</p>
                        </div>
                        {/* <div className="col-6">
                            <p>Professional Assigned</p>
                        </div> */}
                        {/* <div className="col-6 text-end">
                            <p>{milestone?.participant?.name}</p>
                        </div> */}
                        {/* <div className="col-6">
                            <p>Hourly Rate</p>
                        </div> */}
                        {/* <div className="col-6 text-end">
                            <p>{milestone?.participant?.subProfessionalProfile?.compensation}</p>
                        </div> */}
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                        <h6 className="fw-bold">Total Expense of Milestone {index + 1}</h6>
                        <h6 className="text-success">Total: ${parseFloat(milestone.budget) * milestone.hours}</h6>
                        </div>
                    </div>
                    );
                })}
            </div> 
            : '' }

            {data?.status == 'accepted' ?
                <button className="btn btn-success btn-small w-100" disabled>Project Accepted</button>
                :
                isDatePast(data?.due_date) ? <button className="btn btn-danger btn-small w-100" disabled>Project Date Expired on {moment(data?.due_date).format('LL')}</button> : data?.status !== 'pending' && data?.completion_level == 100 ?
                <Spin spinning={loading}>
                    <div className="d-flex justify-content-between align-items-center mb-3 mt-5">
                        <button className="btn btn-success btn-small w-100" onClick={() => completeProjet(data?.id)}>Confirm Project is Completed {data?.due_date}</button>
                    </div>
                </Spin>
                : 
                ''
            }
        </div>
    )
}
import React, {useEffect, useState} from "react";
import { Table, Progress, Tag, Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { serverRequest } from "../../Utils/server_request";
import { ProjectDetails } from "./projectDetails";
import { Pagination } from "../../components/v1/Pagination";


export const RemediationPage = () => {

  const [data, setData] = useState([])
  const [meta, setMeta] = useState({});
  const [singleProject, setSingleProject] = useState({});
  const [drawer, setDrawer] = useState(false);

  const openSingle = (data) => {
    setSingleProject(data)
    setDrawer(true)
  }

const columns = [
  {
    title: "Project Name",
    dataIndex: "title",
    key: "title",
    render: (text, record) => (
      <div className="d-flex align-items-center">
        <i className={record.icon + " fs-4 me-2"}></i>
        <div>
          {text}
        </div>
      </div>
    ),
  },
  // {
  //   title: "Client",
  //   dataIndex: "client",
  //   key: "client",
  //   render: (text, record) => (
  //     <strong>{record.client.toUpperCase()}</strong>
  //   ),
  // },
  {
    title: "Milestone",
    dataIndex: "milestones",
    key: "milestones",
    render: (text, record) => (
      <div>{record.milestones.length} milestones</div>
    ),
  },
  {
    title: "Progress",
    dataIndex: "completion_level",
    key: "completion_level",
    render: (progress) => <Progress percent={progress} status="active" strokeColor="#6bc497"/>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      const statusColors = {
        pending: "gold",
        accepted: "blue",
        completed: "green",
        closed: "red",
      };
      return <Tag color={statusColors[status] || "default"}>{status.toUpperCase()}</Tag>;
    },
  },
  {
    title: "Deadline",
    dataIndex: "due_date",
    key: "due_date",
  },
  {
    title: "Action",
    dataIndex: "ation",
    key: "ation",
    render: (_, record) => <button className="btn btn-small btn-dark" onClick={() => openSingle(record)}>View</button>
  },
];

useEffect(() => {
  fetchProjects()
},[])

const fetchProjects = (page = 1) => {
  serverRequest('GET', `business/remediation?page=${page}`).then(result => {
    if(result.data){
      setData(result.data)
      setMeta(result.meta);
    }
  })
}

  return (
    <div className="">
      <p className="lead mb-4">Remediations</p>
      <Table columns={columns} dataSource={data} pagination={false} />
          {data.length > 10 ?
            <Pagination
                currentPage={meta.current_page}
                perPage={meta.per_page}
                total={meta.total}
                onPageClick={fetchProjects}/>
            : ''}


            <Drawer open={drawer} onClose={() => setDrawer(false)} footer={null} destroyOnClose={true} width='800px' title='Project'>
               <ProjectDetails data={singleProject} getData={() => fetchProjects(meta?.currentPage)} />
            </Drawer>
    </div>
  );
};
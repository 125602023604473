import React, {useEffect, useState} from "react";
import anvillistLogo from "../../assets/anvillist-logo.png";
import {Input, Select, Spin} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import {serverRequest} from "../../Utils/server_request";
import {getURLParameters, isFormComplete, notifyUser} from "../../Utils/general";

export const SignUp = (props) => {

  const navigate = useNavigate();
  const [isNext, setNext] = useState(false)
  const [loading, setLoading] = useState(false)
  const [industries, setIndustries] = useState([])
  const [form, setForm] = useState({
    firstName: '', lastName: '', email: '', industry: '', business_name: '', password: '', phone: ''
  })
  const [error, setError] = useState('');
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  useEffect(() => {
    loadIndustries()
    if(getURLParameters('email')){
      setForm({...form, email: getURLParameters('email')})
    }
  },[])

  const toggleNext = () => setNext(prevState => !prevState)

  const toggleLoading = () => setLoading(prevState => !prevState)

  const changeHandler = (e) => setForm({...form, [e.target.name]: e.target.value})

  const loadIndustries = () => {
    serverRequest('GET','industries')
        .then(result => {
          let _industries = []
          result.map(item => {
            let _data = {
              value: item,
              label: item
            }
            _industries.push(_data)
          })
          setIndustries(_industries)
        })
  }

  const onSubmitRegister = () => {
    if (isFormComplete(form)){
      if (!passwordRegex.test(form.password)) {
        setError('Password must be at least 8 characters, include uppercase, lowercase, number, and symbol.');
        return;
      }else{setError('')}
      toggleLoading()
      const { firstName, lastName, ...rest } = form;
      const payload = {
        ...rest,
        name: `${firstName} ${lastName}`.trim(),
        type: 'business',
        isInvite: getURLParameters('invite') ? true : false,
        businessId: getURLParameters('id'),
        professionalId: getURLParameters('professionalId'),
      };
      serverRequest('POST', 'register', payload).then(result =>{
        if (result?.success){
          notifyUser('success', result?.message, 'Account Created')
          toggleLoading()
          navigate('/verify-account');
        }else{
          notifyUser('error', result?.message, 'Account creation failed')
          toggleLoading()
        }
      }).catch(error => {
        notifyUser('error', error?.message, 'Failed')
        toggleLoading()
      })
    }else {
      notifyUser('error', 'All fields are required!', 'Validation Error')
    }
  }

  return (
      <div>
        <div className="w-50 mx-auto mobile-full-width mobile-padding-top">
          <div align='center'>
            <h2 className="fw-bold">Welcome to Anvillist 👋</h2>
            <small>Your trusted partner on the path to investment readiness.</small>
          </div>

          <div style={{ paddingTop:'10%' }} className='w-75 mx-auto mobile-full-width'>
            <h2 className="fw-bold mb-3">Sign Up</h2>
            <Spin spinning={loading}>
              <div className="firstSection" style={{ display: isNext ? 'none': ''}}>
                <div className="form-group mb-4 mt-5">
                  <label htmlFor="fName">First Name</label>
                  <Input type="text" name='firstName' placeholder="Enter first name" onChange={changeHandler} value={form.firstName}/>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lName">Last Name</label>
                  <Input type="text" name='lastName' placeholder="Enter last name" onChange={changeHandler} value={form.lastName}/>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="email">Business Email</label>
                  <Input type="email" name='email' placeholder="Enter business email" onChange={changeHandler} value={form.email}/>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lName">Business Name</label>
                  <Input type="text" name='business_name' placeholder="Enter business name" onChange={changeHandler} value={form.business_name}/>
                </div>
              </div>
              <div className="secondSection" style={{ display: !isNext ? 'none': ''}}>
                <div className="form-group mb-4 mt-5">
                  <label htmlFor="email">Industry</label>
                  <Select
                      showSearch placeholder="Search to Select" optionFilterProp="label" name='industry' onSelect={value => setForm({...form, industry: value})}
                      filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                      options={industries} />
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="phone">Phone Number</label>
                  <PhoneInput
                      country={'us'}
                      value={form.phone}
                      onChange={phone => setForm({...form, phone: phone})}
                  />
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="email">Password</label>
                  <Input.Password name="password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Enter password"
                                  onChange={changeHandler} value={form.password} />
                  {error && <small style={{ color: 'red' }}>{error}</small>}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-2">
                  {isNext ?
                      <button className="btn btn-primary btn-small fw-bold w-75 mobile-full-width"
                              onClick={isNext ? toggleNext : null}>
                        Previous</button>
                      : ''}
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-2">
                  <button className="btn btn-primary btn-small fw-bold w-75 float-end mobile-full-width"
                          onClick={isNext ? onSubmitRegister : toggleNext}>{isNext ? 'Sign Up' : 'Next'}</button>
                </div>
              </div>
            </Spin>

            <hr className='mt-5'/>
            
            <div className="row align-items-center">
              <div className="col"><p className="mb-0 fw-bold">Already have an account?</p></div>
              <div className="col"><button className="btn btn-primary btn-small float-end" onClick={() => navigate('/login')}>Login</button></div>
            </div>
          </div>
        </div>
      </div>
  )
}